 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.user_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发证机关">
				<el-input class="el_input" v-model="form.dl_ca" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="资格证号码">
				<el-input class="el_input" v-model="form.cy_code" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="准驾车型">
				<el-select class="el_input" v-model="form.dl_class" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in driver_class_list" :key="index" :label="item.dl_class" :value="item.dl_class"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="从业资格证">
				<el-select class="el_input" v-model="form.cy_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未验证" value="1"></el-option>
					<el-option label="验证通过" value="2"></el-option>
					<el-option label="验证失败" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status">
					<el-option label="未上报" value="1"></el-option>
					<el-option label="上报成功" value="2"></el-option>
					<el-option label="上报失败" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="创建时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="司机信息">
					<template slot-scope="scope">
						<div>{{scope.row.user_name}}</div>
						<div>{{scope.row.user_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="准驾车型" prop="dl_class"></el-table-column>
				<el-table-column label="从业资格证状态" prop="cy_status_text"></el-table-column>
				<el-table-column label="上报状态" prop="upl_status_text"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_upl_view(scope.row)" size="mini" type="primary">上报</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 上报相关 -->
		<upl 
			:is_show="upl.is_show" 
			:driver_info="upl.driver_info" 
			:driver_class_list="driver_class_list" 
			@close="upl_save_callback"
		></upl>
	</div>
</template>

<script>
	import upl from './upl.vue'
	export default {
		components:{
			upl
		},
		data() {
			return {

				//搜索条件
				form: {				
					user_tel:'',//司机电话
					user_name:'',//司机姓名
					dl_ca:'',//行驶证发证机关
					dl_class:'',//准驾车型
					cy_status:'',//从业资格证状态
					cy_code:'',//从业资格证号码
					status:2,//状态(1:审核中,2:审核通过,3:审核未通过)
					upl_status:'1',//上报状态(1:未上报,2:上报成功,3:上报失败)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//准驾车型列表
				driver_class_list:[],

				//上报弹出层
				upl:{
					driver_info:{},
					is_show:0,
				},
			}
		},
		created() {

			//读取准驾车型
			this.get_driver_class()
			
			//获取页面数据
			this.get_page_data()
		},
		methods: {

			//打开上报页面
			open_upl_view(item){

				//置入货车数据
				this.upl.driver_info=item

				//打开弹出层
				this.upl.is_show++;
			},
			upl_save_callback(){//上报界面关闭回调
				
				//复位
				this.upl.is_show=0;

				//刷新页面
				this.get_page_data()
			},

			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//状态
							switch(item.status){
								case '1':item.status_text='审核中';break;
								case '2':item.status_text='审核通过';break;
								case '3':item.status_text='审核未通过';break;
							}

							//上报状态
							switch(item.upl_status){
								case '1':item.upl_status_text='未上报';break;
								case '2':item.upl_status_text='上报成功';break;
								case '3':item.upl_status_text='上报失败';break;
							}

							//准驾车型
							if(!item.dl_class){
								item.dl_class="未填写"
							}

							// //驾驶证
							// item.dl_img_src=this.$my.qiniu.make_src('driverdl',item.dl_img)

							// //从业资格证状态
							// item.cy_img_src=this.$my.qiniu.make_src('drivercy',item.cy_img)

							//从业资格证状态
							switch(item.cy_status){
								case '1':item.cy_status_text='未验证';break;
								case '2':item.cy_status_text='验证通过';break;
								case '3':item.cy_status_text='验证失败';break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取准驾车型
			get_driver_class(){

				//尝试从缓存中取出
				let driver_class_list=this.$my.cache.get('driver_class_list');

				//如果取出成功
				if(driver_class_list){
					this.driver_class_list=driver_class_list
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_class_list'
					},
					callback:(data)=>{

						//置入
						this.driver_class_list=data.list

						//缓存之
						this.$my.cache.set({
							driver_class_list:data.list
						});
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	.in_check_img_item{
		width: 500px;
	}

	.in_tab_img_item{
		width: 100px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 100px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>